<template>
  <div class="CmsContentsFiledsList">
    <div class="contentsWidth mx-auto mt-4">

      <div class="">
        <p class="title mb-0">計画変更申請 - 一覧</p>
        <hr class="title">
      </div>

      <div id="serchWrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search_area flex flexCenter">
          <div class="searchShort inlineBlock searchSpace">
            <div>年度</div>
            <div>
              <b-form-select
                @change="fetchChangePlanList"
                v-model="search.year"
                :options="yearList">
                <template v-slot:first>
                  <option :value="'all'">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>助成プログラム</div>
            <div>
              <b-form-select
                v-model="search.series"
                :options="seriesList">
                <template v-slot:first>
                  <option :value="null">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>

          <div class="searchMiddle inlineBlock searchSpace">
            <div>文理区分</div>
            <div>
              <b-form-select
                :options="bunriOption"
                v-model="search.bunri">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>

          <div class="searchMiddle inlineBlock searchSpace">
            <div>申請者名</div>
            <div>
              <b-form-input
                v-model="search.name"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddle inlineBlock searchSpace">
            <div>申請番号</div>
            <div>
              <b-form-input
                v-model="search.code"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="searchMiddle inlineBlock">
            <div>ステータス</div>
            <div>
              <b-form-select
                v-model="search.status"
                :options="statusList">
                <template v-slot:first>
                  <option :value="''">-- 未選択 --</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            :value="currentPage"
            @input="setCurrentPage($event)"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                :value="perPage"
                @change="setPerPage"
                :options="pageOptions"
              />
            </div>
            <!-- <div class="ml-4 inlineBlock">
              <b-link
                to="/cms/cmsUser/create">新規登録</b-link>
            </div> -->
          </div>
        </div>

        <b-table striped hover
          id='fieldTable'
          table-class="cmsChangeListTable"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          :items="changePlanList"
          :fields="header"
          :filter="search"
          :filter-function="filtering"
          @filtered="onFiltered"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
          :filter-included-fields="['bunri']"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:emptyfiltered="scope">
            <div class="flex flexCenter">条件に一致するデータがありません。</div>
          </template>
          <!-- kanaでソートをするが、表示を漢字名 -->
          <template v-slot:cell(kana)="row">
            {{row.item.name}}
          </template>
          <template v-slot:cell(btn)="row">
            <b-link :to="'/cms/applications/change/edit/'+row.item.id">
              詳細
            </b-link>
          </template>
        </b-table>

        <div class="flex flex-between my-2" v-if="totalRows > 0">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            :value="currentPage"
            @input="setCurrentPage($event)"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                :value="perPage"
                @change="setPerPage"
                :options="pageOptions"
              />
            </div>
            <!-- <div class="ml-4 inlineBlock">
              <b-link
                to="/cms/cmsUser/create">新規登録</b-link>
            </div> -->
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import moment from 'moment';
import api from '@/modules/api';
import CONST_STATUS from '@/constants/appStatus';
// import download from '@/modules/download';

export default {
  name: 'cmsApplicationsChangeList',
  data() {
    return {
      status: CONST_STATUS.APP_STATUS,
      statusList: CONST_STATUS.CMS_CHANGE_STATUS_OPTION,
      dbChangePlanList: [],
      header: [
        // { key: 'checkbox', label: '', sortable: false },
        { key: 'kana', label: '申請者名', sortable: true },
        { key: 'institution', label: '所属', sortable: true },
        { key: 'apptype_name', label: '助成プログラム', sortable: true },
        { key: 'code', label: '申請番号', sortable: true },
        { key: 'sort', label: '計画変更申請番号', sortable: true },
        { key: 'statusText', label: 'ステータス', sortable: true },
        { key: 'btn', label: '申請書', sortable: false },
      ],
      pageOptions: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        // { value: 0, text: 'すべて' },
      ],
      rawSeriesList: [],
      yearList: [],
      isInit: true,
      oldPage: 1,
      sortBy: 'code',
      sortDesc: true,
    };
  },
  methods: {
    async initFetch() {
      const promiseFuncs = [api.send('/api/cms/applications/change/init')];
      const responses = await api.all(promiseFuncs)
        .catch((err) => {
          console.log(err);
        });
      this.rawSeriesList = responses[0].data.seriesList;
      this.yearList = responses[0].data.yearList;
      // 現在の検索年がリストになければ、リスト先頭を選択状態にする。
      if (this.yearList.indexOf(this.search.year) === -1) {
        this.$set(this.search, 'year', this.yearList[0]);
      }
    },
    // 報告一覧取得
    async fetchChangePlanList() {
      if (!this.search.year) {
        return;
      }
      if (this.search.year !== null && this.search.year !== '' && this.search.year !== 'all') {
        if (this.search.year < 2025) {
          if (this.search.series === '学術調査研究助成') {
            this.$set(this.search, 'series', '調査研究助成');
          }
          if (this.search.series === 'デジタルイノベーション社会実装助成') {
            this.$set(this.search, 'series', '目的型調査研究助成');
          }
        } else {
          if (this.search.series === '目的型諸活動助成') {
            this.$set(this.search, 'series', null);
          }
          if (this.search.series === '調査研究助成') {
            this.$set(this.search, 'series', '学術調査研究助成');
          }
          if (this.search.series === '目的型調査研究助成') {
            this.$set(this.search, 'series', 'デジタルイノベーション社会実装助成');
          }
        }
      }
      this.$store.dispatch('page/onWaiting');
      const param = {
        year: this.search.year,
        series: this.search.series,
      };
      const response = await api.send('/api/cms/applications/change/list', param)
        .catch((err) => {
          console.log(err);
        });
      this.dbChangePlanList = response.data.changePlanList;
      this.$store.dispatch('page/offWaiting');
    },
    // フィルター用 search変更時に各行に対して走る
    filtering(lineData, search) {
      const nameSort = search.name === '' || lineData.name.includes(search.name);
      const yearSort = search.year === null || search.year === 'all' || lineData.year === search.year;
      const codeSort = search.code === '' || (lineData.code && lineData.code.includes(search.code));
      const statusSort = search.status === '' || lineData.status === Number(search.status);
      const yearBunri = search.bunri === '' || lineData.bunri === search.bunri;
      const series = search.series === null || search.series === '' || lineData.apptype_name === search.series || (search.series === '社会的文化的諸活動助成' && lineData.apptype_name === '社会的・文化的諸活動助成');
      return yearSort && codeSort && statusSort && yearBunri && series && nameSort;
    },
    onFiltered(filteredItems) {
      this.$store.commit('cmsChangePlansSearch/setTotalRows', filteredItems.length);
      if (this.isInit) {
        this.$store.commit('cmsChangePlansSearch/setCurrentPage', this.oldPage);
      } else {
        this.$store.commit('cmsChangePlansSearch/setCurrentPage', 1);
      }
      // 編集ページ内での次の報告、前の報告に移動するためのIDリストの保存
      this.$store.commit('cmsChangePlansSearch/initSelectedAppid');
      this.$store.commit('cmsChangePlansSearch/setFilterdIdList', filteredItems);
    },
    setCurrentPage(page) {
      this.$store.commit('cmsChangePlansSearch/setCurrentPage', page);
    },
    setPerPage(page) {
      this.$store.commit('cmsChangePlansSearch/setPerPage', page);
    },
    setTotalRows(value) {
      this.$store.commit('cmsChangePlansSearch/setTotalRows', value);
    },
  },
  computed: {
    bunriOption() {
      return ['文', '理'];
    },
    changePlanList() {
      if (!this.dbChangePlanList) {
        return [];
      }
      const changePlanList = this.dbChangePlanList.map((changePlan) => {
        let bunri = '';
        if (changePlan.bunri === 1 || changePlan.bunri === 2) {
          bunri = this.bunriOption[changePlan.bunri - 1];
        }
        let name = '';
        let kana = '';
        if (changePlan.ans_name && changePlan.ans_kana) {
          changePlan.ans_name.forEach((data) => {
            name += `${data.field_answer_text} `;
          });
          changePlan.ans_kana.forEach((data) => {
            kana += `${data.field_answer_text} `;
          });
        }
        let institution = '';
        if ((changePlan.apptype_name === '国際会議開催助成' || changePlan.apptype_name === '社会的・文化的諸活動助成') && changePlan.ans_apply_org.length > 0) {
          const applyOrg = changePlan.ans_apply_org[0];
          institution = applyOrg.field_answer_text;
        } else {
          institution = changePlan.university_name;
          if (!institution) {
            // eslint-disable-next-line
            institution = changePlan.institution;
          }
        }
        return {
          id: changePlan.changePlanId,
          apptype_name: changePlan.apptype_name,
          name,
          kana,
          bunri,
          year: changePlan.year,
          code: changePlan.code,
          status: changePlan.status,
          statusText: CONST_STATUS.APP_CHANGE_STATUS_TEXTS[changePlan.status],
          sort: changePlan.sort,
          institution,
        };
      });
      return changePlanList;
    },
    search() {
      return this.$store.state.cmsChangePlansSearch.search;
    },
    currentPage() {
      return this.$store.state.cmsChangePlansSearch.currentPage;
    },
    perPage() {
      return this.$store.state.cmsChangePlansSearch.perPage;
    },
    totalRows() {
      return this.$store.state.cmsChangePlansSearch.totalRows;
    },
    adjustedApptypeData() {
      let optionDbData = this.rawSeriesList;
      if (this.search.year === 'all') {
        optionDbData = [];
        this.rawSeriesList.forEach((series) => {
          optionDbData.push(series);
          if (series.id === 2) {
            optionDbData.push({ id: series.id, name: '調査研究助成' });
          }
          if (series.id === 8) {
            optionDbData.push({ id: series.id, name: '目的型調査研究助成' });
          }
        });
      }

      return optionDbData;
    },
    seriesList() {
      const filterSeriesList = [];
      this.adjustedApptypeData.forEach((data) => {
        if ((this.search.year !== null && (this.search.year === 'all' || this.search.year < 2025)) || data.id !== 9) {
          filterSeriesList.push(data);
        }
      });
      const appTypeList = filterSeriesList.map((series) => {
        if (this.search.year !== null) {
          if (this.search.year !== 'all' && this.search.year < 2025 && series.id === 2) {
            return { value: '調査研究助成', text: '調査研究助成', id: 2 };
          }
          if (this.search.year !== 'all' && this.search.year < 2025 && series.id === 8) {
            return { value: '目的型調査研究助成', text: '目的型調査研究助成', id: 8 };
          }
        }
        return { value: series.name, text: series.name, id: series.id };
      });
      return appTypeList;
    },
    selectedRepIdList() {
      if (!this.$store.state.cmsChangePlansSearch.selectedRepIdList) {
        return [];
      }
      return this.$store.state.cmsChangePlansSearch.selectedRepIdList;
    },
    filterdIdList() {
      return this.$store.state.cmsChangePlansSearch.filterdIdList;
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    this.oldPage = this.currentPage;
    await this.initFetch();
    await this.fetchChangePlanList();
    this.isInit = false;
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style>
  .cmsChangeListTable thead th:nth-of-type(0) {
    width: 140px !important;
  }
  .cmsChangeListTable thead th:nth-of-type(5) {
    width: 120px !important;
  }
  .cmsChangeListTable thead th:nth-of-type(6) {
    width: 120px !important;
  }
  .cmsChangeListTable thead th:nth-of-type(7) {
    width: 120px !important;
  }
</style>

<style scoped>
  #serchWrap input, #serchWrap select {
    height: 50px;
  }

  #NumberWrap {
    height: 50px;
  }

  .search_area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }

  .searchMiddle {
    width: 205px;
  }

  .searchShort {
    width: 135px;
  }

  .searchSpace {
    margin-right: 15px;
  }

  #popover {
    cursor: pointer;
    color: #FFF !important;
    text-decoration: underline;
  }

  .link {
    color: #0A8EA7 !important;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }

  .err-wrap>p{
    color: #dc3545;
  }
</style>
